<template>
  <ul >
      <li v-for="(movie, index) in movies"  :key="index"  >
        <router-link :to="`/Movie/${movie.id}`">
        <img :src="`${URL_IMG}${movie.poster_path}`" alt="" />
        <h1>{{movie.title}}</h1>
        </router-link>
      </li>
  </ul>
  <numberPageMovies/>
  {{selectionPage()}}
</template>

<script>

import axios from 'axios'
import numberPageMovies from './numberPageMovies.vue'

export default {
  name: 'Movies',
  components: {
    numberPageMovies
  },

  data(){
      return{
          API_KEY: 'api_key=d3605b50723561374d71cecc92897a06',
          movies: '',
          id:'',
          URL_IMG: 'https://image.tmdb.org/t/p/w500',
      }
  },

  methods:{
    selectionPage(){

      axios
        .get(`https://api.themoviedb.org/3/discover/movie?${this.API_KEY}&language=fr&sort_by=popularity.desc&include_adult=false&include_video=false&page=${this.$route.params.page}&with_watch_monetization_types=flatrate`)
        .then(response => {
          this.movies = response.data.results
        })
    },
  }

}
</script>

<style scoped lang="scss">

ul{
    padding: initial;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;
    list-style-type: none;
    a{
      text-decoration: none;
    }
    li{
      width: 20vw;
      img{
        width: 100%;
      }
    }
}
</style>
