<template>
  <nav class="nav">
      <router-link to='/'>Accueil</router-link>
      <router-link to='/Movies/1'>Films</router-link>
      <router-link to='/Series/1'>Series</router-link>
      
  <SearchMovies/>
  </nav>
</template>

<script>

import SearchMovies  from './SearchMovies'

export default {

  
  name: 'NavBar',
  components: {
      SearchMovies
      
  },
  
}
</script>

<style lang="scss">

.nav{
    text-align: center;
    font-size: 1.8em;
    a{
        text-decoration: none;
        width: min-content;
        margin: 7px;
    }
}

</style>
