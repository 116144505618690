<template>
  <h1>En cours de création</h1>
  
</template>

<script>


export default {
  name: 'Home',
  components: {
    
  }
}
</script>

<style lang="scss">

</style>
