import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home'
import Movies from '../components/Movies'
import Series from '../components/Series'
import ResultsMovies from '../components/ResultsMovies'
import MoviesDetails from '../components/details/MoviesDetails'
import TVDetails from '../components/details/TVDetails'


const routes = [

  {path:'/', component:Home, name:'Home'},

  {path:'/Movies/:page', component:Movies, name:'Movie'},

  {path:'/Series/:page', component:Series, name:'Series'},

  {path:'/ResultsMovies/:query', component:ResultsMovies, name:'ResultsMovies'},
  
  {path:'/Movie/:id', component:MoviesDetails, name:'MoviesDetails'},

  {path: '/Serie/:id', component:TVDetails, name:'TVDetails'}

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
