<template>
  <NavBar class="navbar"/>
</template>

<script>

import NavBar from './NavBar.vue'

export default {
  name: 'Header',
  components: {
      NavBar
  }
}
</script>

<style scoped lang="scss">
header{
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
}

img{
    width: 20%;
    height: 20vh;
    position: absolute;
}
</style>
