<template>
  <div class="containerMovie">
      <img :src="`${URL_IMG}${movies.poster_path}`" alt=""/>
      <div class="detailMovie">
        <h1> {{movies.title}} </h1>
        <p>{{movies.overview}}</p>
        <button v-on:click='retour'>retour</button>
      </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'MoviesDetails',
  components: {
  },

  data(){
      return{
          API_KEY: 'api_key=d3605b50723561374d71cecc92897a06',
          movies: '',
          URL_IMG: 'https://image.tmdb.org/t/p/w500',
          id: this.$route.params.id        
      }
  },

  methods: {

      retour(){
          this.$router.back()
      }

  },

  mounted(){
      axios
      .get(`https://api.themoviedb.org/3/movie/${this.id}?${this.API_KEY}&language=fr`)
      .then(response => {
          console.log(response.data.title)
          this.movies = response.data
      })
  }
}
</script>

<style scoped lang="scss">

ul{
    padding: initial;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;
    list-style-type: none;
}

.containerMovie{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly; 
    background-repeat: no-repeat;
    background-size: 100%; 
}
.detailMovie{
    width: 35%;
    display: flex;
    display: flex;
    flex-direction: column;
    background-color: rgba(255,255,255, 0.7);
    h1{
        text-align: center;
    }
    button{
        width: min-content;
    }
}
</style>
