<template>

<header>
  <header-top></header-top>
</header>

<section>
  <router-view></router-view>

</section>

</template>

<script>

import Header from './components/header/Header.vue'

export default {
  name: 'App',
  components:{
    'header-top': Header
  },

  data(){
    return{
  
    }
  },

  methods:{
   
  }
}
</script>

<style lang="scss">
  a{
    color: black;
  }
</style>
