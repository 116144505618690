<template>
  <div>
    
    <input type="text" 
    v-on:keyup.enter='searchmovies' 
    v-model="query"/>
  </div>

</template>

<script>

export default {
  name: 'SearchMovies',
  components: {
  },

  data(){
    return{
      query:""
    }
  },

  methods:{
      searchmovies(){
      this.$router.push(`/ResultsMovies/${this.query}`)
      this.query=''
      }
      }
}
</script>

<style lang="scss">

</style>
