<template>



    <ul>
      <li v-for="(result, index) in results" :key="index" :id="result.id" :result='result' >
        <router-link :to="`/Movie/${result.id}`">
        <img :src="`${URL_IMG}${result.poster_path}`" alt=""/>
        <h1>
          {{result.title}}
        </h1>
        </router-link>
      </li>
    </ul>

{{resultseach()}}

</template>

<script>

import axios from 'axios'

export default {
  name: 'ResultsMovies',
  components: {
  },
 
  data(){
      return{
        results:'',
        API_KEY: 'api_key=d3605b50723561374d71cecc92897a06',
        URL_IMG: 'https://image.tmdb.org/t/p/w500'      
      }
  },

  methods : {

    resultseach(){
      // const ctx=this
      axios
      .get(`https://api.themoviedb.org/3/search/multi?${this.API_KEY}&query=${this.$route.params.query}&language=fr&page=1&include_adult=false`)
      .then(response => {
        this.results =response.data.results
        console.log(this.results)
      })
    }
   
    }
    
  }
  

</script>

<style scoped lang="scss">

ul{
    padding: initial;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;
    list-style-type: none;
    a{
      text-decoration: none;
    }
    li{
      width: 20vw;
      img{
        width: 100%;
      }
    }
}
</style>

