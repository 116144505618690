<template>
  <ul>
      <li v-for="(serie, index) in series" :key="index" class="card_movie" >
          <router-link :to="`/Serie/${serie.id}`">
          <img :src="`${URL_IMG}${serie.poster_path}`" alt="" class="img_movie"/>
          <h1>{{serie.name}}</h1>
          </router-link>
      </li>
  </ul>
 
 <numberPageSeries/>
 {{selectionPage()}}
</template>

<script>

import axios from 'axios'
import numberPageSeries from './numberPageSeries.vue'


export default {
  name: 'Series',
  components: {
      numberPageSeries
  },

  data(){
    return{
        API_KEY: 'api_key=d3605b50723561374d71cecc92897a06',
        series: '',
        URL_IMG: 'https://image.tmdb.org/t/p/w500'       
      }
  },

  methods:{
    selectionPage(){
      axios
        .get(`https://api.themoviedb.org/3/discover/tv?${this.API_KEY}&language=fr&sort_by=popularity.desc&include_adult=false&include_video=false&page=${this.$route.params.page}&with_watch_monetization_types=flatrate`)
        .then(response => {
          this.series = response.data.results
        })
  }
    },
}
</script>

<style scoped lang="scss">

ul{
    padding: initial;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;
    list-style-type: none;
    a{
      text-decoration: none;
    }
    li{
      width: 20vw;
      img{
        width: 100%;
      }
    }
}
</style>
