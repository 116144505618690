<template>
<ul>
  <li v-for="page of total_pages" :key="page">
    
  <router-link :to="`/Series/${page}`"> {{page}} </router-link>
  </li>
</ul>

</template>

<script>

import axios from 'axios'

export default {
  name: 'numberPageSeries',
  components: {
  },

  data(){
      return{
          API_KEY: 'api_key=d3605b50723561374d71cecc92897a06',
          page:'',
          total_pages: ''
      }
  },

  mounted(){
      
      axios
      .get(`https://api.themoviedb.org/3/discover/tv?${this.API_KEY}&language=fr&sort_by=popularity.desc&include_adult=false&include_video=false&page=${this.page}&with_watch_monetization_types=flatrate`)
      .then(response => {
          console.log(response.data)
          this.page=response.data.page
          this.total_pages = 50
      })
      
  }
}
</script>

<style scoped lang="scss">

ul{
    padding: initial;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;
    list-style-type: none;
}
</style>
